import { LAYOUT_BO } from "@/constants";
import { LazyLoadComponentWrapper } from "@/utils/lazy-load-component-wrapper";
import { P2PGuard } from "@/utils/auth";
import PassThrough from "@/views/pass-through";

const p2pMarketRouter = {
  path: "p2p-market",
  component: PassThrough,
  beforeEnter: P2PGuard,
  meta: {
    layout: LAYOUT_BO,
    menuTitleTransCode: "P2P_MARKETPLACE",
    menuIcon: "icon-route-p2p-market",
    allowedRoles: ["ADMIN", "MANAGER", "OFFICER", "MEMBER"]
  },
  children: [
    {
      path: "",
      component: () => LazyLoadComponentWrapper(import("@/views/bo/p2p-market/index.vue")),
      meta: {
        layout: LAYOUT_BO,
        breadcrumbTransCodeList: ["P2P_MARKETPLACE"],
        allowedRoles: ["ADMIN", "MANAGER", "OFFICER", "MEMBER"]
      },
    },
    // FIXME WENDY - confirm roles access right 
    {
      path: "buy-detail/:id(\\d+)?",
      component: () => LazyLoadComponentWrapper(import("@/views/bo/p2p-market/buy/detail.vue")),
      meta: {
        layout: LAYOUT_BO,
        breadcrumbTransCodeList: ["P2P_MARKETPLACE", "BUY_REQUEST"],
        allowedRoles: ["ADMIN", "MANAGER", "OFFICER", "MEMBER"]
      },
    },
    {
      path: "sell-detail/:id(\\d+)?",
      component: () => LazyLoadComponentWrapper(import("@/views/bo/p2p-market/sell/detail.vue")),
      meta: {
        layout: LAYOUT_BO,
        breadcrumbTransCodeList: ["P2P_MARKETPLACE", "SELL_REQUEST"],
        allowedRoles: ["ADMIN", "MANAGER", "OFFICER", "MEMBER"]
      },
    },
    {
      path: "transaction/:refId",
      component: () => LazyLoadComponentWrapper(import("@/views/bo/p2p-market/transaction-detail.vue")),
      meta: {
        layout: LAYOUT_BO,
        breadcrumbTransCodeList: ["P2P_MARKETPLACE", "TRANSACTION_DETAILS"],
        allowedRoles: ["ADMIN", "MANAGER", "OFFICER", "MEMBER"]
      },
    },
  ]
};

export default p2pMarketRouter;
