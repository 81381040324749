import { checkNotification } from "@/api/notification.js"

const state = {
  haveUnread: false,
  interval: null,
};

function changeFaviconIcon(haveNotification) {
  var link = document.querySelector("link[rel~='icon']");
  if (!link) {
    link = document.createElement('link');
    link.rel = 'icon';
    document.getElementsByTagName('head')[0].appendChild(link);
  }
  if (haveNotification) {
    link.href = '/logo_collapsed_with_notification.svg'
  } else {
    link.href = "/logo_collapsed.png"
  }
}

const actions = {
  loopCheckNotification({ dispatch, commit, state }) {
    dispatch('checkNotification') // call once initially

    if (state.interval != null) {
      dispatch('cancelLoopCheckNotification')
    }

    const interval = setInterval(() => {
      dispatch('checkNotification')
    }, 5000)

    commit('SET_INTERVAL', interval)
  },
  checkNotification({ commit, dispatch, rootGetters }) {
    if (!rootGetters.isAuthenticated) {
      dispatch('cancelLoopCheckNotification')
      return
    }
    checkNotification().then(res => {
      // console.log("(loop) calling checkNotification")
      if (res.status == "ok" && res.result == true) {
        this.haveUnread = true
        changeFaviconIcon(true)
        commit('SET_HAVE_UNREAD', true)
      } else {
        changeFaviconIcon(false)
        commit('SET_HAVE_UNREAD', false)
      }
    })
  },
  cancelLoopCheckNotification({ commit, state }) {
    clearInterval(state.interval)
    commit('SET_INTERVAL', null)

    changeFaviconIcon(false)
    commit('SET_HAVE_UNREAD', false)
  }
};

const mutations = {
  SET_HAVE_UNREAD: (state, haveUnread) => {
    state.haveUnread = haveUnread
  },
  SET_INTERVAL: (state, interval) => {
    state.interval = interval
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
