import Vue from "vue";
import default_layout from "../layouts/default-layout.vue";
import bo_layout from "../layouts/bo-layout.vue";

// ElementUI
import ElementUI from 'element-ui';
import enLang from 'element-ui/lib/locale/lang/en'

Vue.use(ElementUI, {
  size: 'medium', // set element-ui default size
  locale: enLang //  set element-ui default language
})

Vue.component("default-layout", default_layout);
Vue.component("bo-layout", bo_layout);

import store from '@/store'
Vue.filter('translate', store.getters['app/getNameFromTransList'])
Vue.filter('truncate', (value, length) => {
  if (value.length > length) {
    return value.substring(0,length)+"..."
  } else {
    return value
  }
})

import "./tabs-control"

window.Vue = Vue;

// Dont import everything here
// Only import those will be used by many components here.
// Else, import individually in respective component
// Reason: reduce webpack size and thus increase performance.
