import { LAYOUT_BO } from "@/constants";
import { LazyLoadComponentWrapper } from "@/utils/lazy-load-component-wrapper";
import { ifAuthenticated } from "@/utils/auth";
import PassThrough from "@/views/pass-through";

const siteSettingsRouter = {
  path: "site-settings",
  component: PassThrough,
  beforeEnter: ifAuthenticated,
  meta: {
    layout: LAYOUT_BO,
    menuTitleTransCode: "SITE_SETTINGS",
    menuIcon: "icon-route-site-settings",
    allowedRoles: ["ADMIN"]
  },
  children: [
    {
      path: "",
      component: () => LazyLoadComponentWrapper(import("@/views/bo/site-settings/index.vue")),
      meta: {
        layout: LAYOUT_BO,
        breadcrumbTransCodeList: ["SITE_SETTINGS"],
        allowedRoles: ["ADMIN"]
      },
    },
    {
      path: "contact-us",
      component: () => LazyLoadComponentWrapper(import("@/views/bo/site-settings/contact-us.vue")),
      meta: {
        layout: LAYOUT_BO,
        breadcrumbTransCodeList: ["CONTACT_US"],
        allowedRoles: ["ADMIN"]
      },
    },
    {
      path: "privacy-policy",
      component: () => LazyLoadComponentWrapper(import("@/views/bo/site-settings/privacy-policy.vue")),
      meta: {
        layout: LAYOUT_BO,
        breadcrumbTransCodeList: ["PRIVACY_POLICY"],
        allowedRoles: ["ADMIN"]
      },
    },
    {
      path: "social-media-links",
      component: () => LazyLoadComponentWrapper(import("@/views/bo/site-settings/social-media-links.vue")),
      meta: {
        layout: LAYOUT_BO,
        breadcrumbTransCodeList: ["SOCIAL_MEDIA_LINKS"],
        allowedRoles: ["ADMIN"]
      },
    },
    
  ]
};

export default siteSettingsRouter;
