
const state = {
  draftArticle: localStorage.getItem("draft") || {},
}

const actions = {
  previewArticle({ commit },data) {
    commit('SET_DRAFT_ARTICLE', data)
  },
}

const mutations = {
  SET_DRAFT_ARTICLE: (state, data) => {
    localStorage.setItem("draft", JSON.stringify(data))
    state.draftArticle = data
  },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
  };
  