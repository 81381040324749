import request from "@/utils/request";

export async function upload(data) { // only raw file uploads
  return await request({
    url: '/upload/create',
    method: "post",
    data
  });
}

export async function fileUpload(data) { // upload + additional information like language
  return await request({
    url: '/file-upload/create',
    method: "post",
    data
  });
}

export async function getBankList() {
  return await request({
    url: '/generic-record/list',
    method: "post",
    data: {
      genericRecord: { type: "bank" }
    }
  });
}

export async function getRoleList() {
  return await request({
    url: '/generic-record/list',
    method: "post",
    data: {
      genericRecord: { type: "role" }
    }
  });
}

export async function getComplainTypeList() {
  return await request({
    url: '/generic-record/list',
    method: "post",
    data: {
      genericRecord: { type: "complain-type" },
      orderCol: "id",
      desc: false,
    }
  });
}

export async function getAdsPositionList() {
  return await request({
    url: '/generic-record/list',
    method: "post",
    data: {
      genericRecord: { type: "advertisement_banner_position" }
    }
  });
}

export async function getCurrencyList() {
  return await request({
    url: '/generic-record/list',
    method: "post",
    data: {
      genericRecord: { type: "currency" }
    }
  });
}

/* 
  ======================== Generic Records APIs ========================
  Generic Records APIs Used for: 
  type = gps, hide-show and 
    those which used genericRecordsListMixin and genericRecordsDetailMixin
  
  for get list, need to add the following in the postData
  postData.genericRecord = {
    status: 'ACTIVE',
    type: 'meter_brand'
  }
*/
export async function getGenericRecordsList(postData) {
  return await request({
    url: '/generic-record/list',
    method: "post",
    data: postData,
  });
}
export async function getGenericRecordsDetail(id) {
  return await request({
    url: '/generic-record/' + id,
    method: "get",
  });
}
export async function getGenericRecordsByCodeAndType(code, type) {
  return await request({
    url: `/generic-record/${code}/${type}`,
    method: "get",
  });
}
export async function updateGenericRecordsDetail(postData) {
  return await request({
    url: '/generic-record/update',
    method: "post",
    data: postData,
  });
}
export async function bulkUpdateGenericRecordsDetail(postData) {
  return await request({
    url: '/generic-record/bulk-update',
    method: "post",
    data: postData,
  });
}
export async function addGenericRecordsDetail(postData) {
  return await request({
    url: '/generic-record/create',
    method: "post",
    data: postData,
  });
}
export async function archiveGenericRecordsDetail(id) {
  return await request({
    url: '/generic-record/' + id,
    method: "delete",
  });
}
export async function bulkArchiveGenericRecords(postData) {
  return await request({
    url: '/generic-record/bulk',
    method: "delete",
    data: postData,
  });
}