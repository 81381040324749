import { LAYOUT_BO } from "@/constants";
import { LazyLoadComponentWrapper } from "@/utils/lazy-load-component-wrapper";
import { ifAuthenticated } from "@/utils/auth";
import PassThrough from "@/views/pass-through.vue";

const userRouter = {
  path: "user",
  component: PassThrough,
  beforeEnter: ifAuthenticated,
  meta: {
    layout: LAYOUT_BO,
    menuTitleTransCode: "USERS",
    menuIcon: "icon-route-user",
    allowedRoles: ["ADMIN", "MANAGER", "OFFICER"]
  },
  children: [
    {
      path: "",
      component: () => LazyLoadComponentWrapper(import("@/views/bo/user/list.vue")),
      meta: {
        layout: LAYOUT_BO,
        breadcrumbTransCodeList: ["USER_MANAGEMENT"],
      },
    },
    {
      path: "detail/:uuid?",
      component: () => LazyLoadComponentWrapper(import("@/views/bo/user/detail.vue")),
      meta: {
        layout: LAYOUT_BO,
        breadcrumbTransCodeList: ["USER_MANAGEMENT", "USER_MANAGEMENT_DETAIL"],
      },
    }
  ]
};

export default userRouter;
