import { LAYOUT_BO } from "@/constants";
import { LazyLoadComponentWrapper } from "@/utils/lazy-load-component-wrapper";
import { ifAuthenticated } from "@/utils/auth";
import PassThrough from "@/views/pass-through";

const channelRouter = {
  path: "channel",
  component: PassThrough,
  beforeEnter: ifAuthenticated,
  meta: {
    layout: LAYOUT_BO,
    menuTitleTransCode: "NEWS_CHANNEL",
    menuIcon: "icon-route-channel",
    allowedRoles: ["ADMIN", "MANAGER", "OFFICER"]
  },
  children: [
    {
      path: "",
      component: () => LazyLoadComponentWrapper(import("@/views/bo/channel/list.vue")),
      meta: {
        layout: LAYOUT_BO,
        breadcrumbTransCodeList: ["NEWS_CHANNEL_MANAGEMENT"],
      },
    },
    {
      path: "detail/:id(\\d+)?",
      component: () => LazyLoadComponentWrapper(import("@/views/bo/channel/detail.vue")),
      meta: {
        layout: LAYOUT_BO,
        breadcrumbTransCodeList: ["NEWS_CHANNEL_MANAGEMENT", "NEWS_CHANNEL_DETAIL"],
      },
    }
  ]
};

export default channelRouter;
