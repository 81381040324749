<template>
  <div>
    <div id="bo-main-wrapper">
      <div id="main-container">
        <default-header />
        <el-row
          id="app-main"
          type="flex"
          justify="stretch"
        >
          <default-sidebar />
          <div id="app-main-content">
            <breadcrumb />
            <slot />
          </div>
          <default-footer />
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import breadcrumb from "@/components/breadcrumb"
import Footer from "@/components/header-footer/footer.vue";
import Header from "@/components/header-footer/header.vue";
import SideBar from "@/components/header-footer/sidebar.vue";

export default {
  name: "Admin",
  components: {
    "default-header": Header,
    "default-footer": Footer,
    "default-sidebar": SideBar,
    breadcrumb
  },
  computed: { },
  data() {
    return { };
  },
  watch: { },
  mounted() { },
  methods: { }
};
</script>

<style lang="scss">
@import "@/assets/style/_variables.scss";
#bo-main-wrapper {
  #app-main {
    top: #{$header2Height + $header3Height};
    height: 100%;
    width: 100%;
    position: fixed;
    left: 0;
    overflow: hidden;
    max-width: $viewMaxWidth;
    margin: auto;

    @media (min-width: $viewMaxWidth) {
      left: calc((100vw - $viewMaxWidth) / 2);
    }

    #app-main-content {
      width: 100%;
      margin: 0 1rem auto;
      >*:not(#breadcrumb) {
        margin-bottom: 1rem;
      }
    }

    #sidebar,
    #app-main-content {
      overflow: scroll;
      height: calc(100% - $footerHeight - $header2Height - $header3Height);
      &::-webkit-scrollbar { display: none } // hide scrollbar
    }
  }
}

.mobile {
  #bo-main-wrapper {
    #app-main {
      top: #{$header1HeightMobile + $header2HeightMobile + $header3HeightMobile};
      min-height: calc(100vh - $breadcrumbHeight + 15px);
      padding-left: $spacingLeftRightMobile;
    }
  }
}

</style>
