<template>
  <div
    :style="{
      paddingTop: addTopPadding + 'em',
    }"
  >
    <div id="default-main-wrapper">
      <div id="main-container">
        <default-header @add-top-padding="addHeaderTopPadding"/>
        <section
        id="app-main"
        >
          <slot />
        </section>
        <channelLinkFooter v-if="!hideChannelLinkFooter" />
        <default-footer />
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/components/header-footer/header.vue";
import Footer from "@/components/header-footer/footer.vue";
import channelLinkFooter from "@/components/header-footer/channel-link-footer.vue"

export default {
  name: "Admin",
  components: {
    "default-header": Header,
    "default-footer": Footer,
    channelLinkFooter
  },
  computed: {
    hideChannelLinkFooter() {
      const isLoading = this.$route.name === null
      return this.$route.meta.hideChannelLinkFooter || isLoading
    }
  },
  data() {
    return { 
      addTopPadding: 0
    };
  },
  watch: { },
  mounted() { },
  methods: { 
    addHeaderTopPadding(padding) {
        this.addTopPadding = padding
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/style/_variables.scss";

#default-main-wrapper {
  position: relative;
  
  #main-container {
    min-height: 100vh;
    position: relative;
    
    #app-main {
      padding: $spacingLeftRight;
      padding-top: #{$header2Height + $header3Height + 10px};
      padding-bottom: $footerHeight;
      min-height: 100vh;
      height: calc(100% - 1rem);
      width: 100%;
      position: relative;
      overflow: hidden;
      max-width: $viewMaxWidth;
      margin: auto;
    }
    
  }
}

.mobile #default-main-wrapper #main-container #app-main {
  padding: $spacingLeftRightMobile;
  padding-top: #{$header2HeightMobile + $header3HeightMobile + $header1HeightMobile};
  min-height: calc(100vh - $header1HeightMobile - $header2HeightMobile - $header3HeightMobile);
}
</style>
