import request from "@/utils/request";

export async function getUserList(data) {
  return await request({
    url: '/user/list',
    method: "post",
    data,
  });
}

export async function getUserProfile(uuid) {
  return await request({
    url: `/user/${uuid}`,
    method: "get",
  });
}

export async function updateProfile(data) {
  return await request({
    url: '/user/admin/update',
    method: "post",
    data,
  });
}

export async function updateLanguage(data) {
  return await request({
    url: '/user/update-language',
    method: "post",
    data,
  });
}