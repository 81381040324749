export const API_URL = process.env.VUE_APP_API_URL;
export const API_URL_STATIC = process.env.VUE_APP_API_URL_STATIC;
export const APP_NAME = process.env.VUE_APP_TITLE;

export const LAYOUT_DEFAULT = "default";
export const LAYOUT_BO = "bo";

export const ALL_ROLES = ["ADMIN", "MEMBER"]

export const MOBILE_MAX_WIDTH = 992; // refer to Bootstrap's responsive design
export const RESERVED_KEYWORD = "Newsily";

export const DATE_FORMAT = "DD-MM-YYYY"
export const DATE_TIME_FORMAT = "DD-MM-YYYY HH:mm"
export const EL_DATE_FORMAT = "dd-MM-yyyy"
export const EL_DATE_FORMAT_MOBILE = "dd-MM-yy"
export const TIME_FORMAT = "hh:mm A"

// Element UI
export const NOTIFY_POSITION = "bottom-right";
export const NOTIFY_DURATION = 5000;

// Code for identification

export const DEFAULT_TIMEOUT = 120000; // 2mins
export const EXTENDED_TIMEOUT = 1200000; // 20mins
export const NO_TIMEOUT = 0;

// === OTHERS ===
export const ALL_FILE_TYPE = {
  IMAGE: ['.tif', '.tiff', '.bmp', '.jpg', '.jpeg', '.gif', '.png'],
  EXCEL: ['.csv', '.xlsx', '.xls']
}

export const PAGE_SIZES = [10, 20, 50, 100, 200];

export const AVAIL_STATUS = [
  {
    value: "ACTIVE",
    class: "status-active",
  },
  {
    value: "INACTIVE",
    class: "status-inactive",
  },
  {
    value: "ARCHIVED",
    class: "status-archived",
  },
  {
    value: "PENDING",
    class: "status-pending",
  },
  {
    value: "PENDING_APPROVAL_CREATE",
    class: "status-pending",
  },
  {
    value: "APPROVED",
    class: "status-approved",
  },
  {
    value: "REJECTED",
    class: "status-rejected",
  },
  {
    value: "EXPIRED",
    class: "status-inactive",
  },
  {
    value: "SUSPENDED",
    class: "status-inactive",
  },
  // P2P
  {
    value: "OPEN",
    class: "status-active",
  },
  {
    value: "CANCELLED",
    class: "status-rejected", // use same
  },
];

export const SELECTABLE_STATUS = ["ACTIVE", "INACTIVE"]

export const ALL_LOG_TYPE = [ // translated by front-end
  "POST_ARTICLE", "COMMENT", "ATTENDANCE", "P2P", "SHARE_ARTICLE", "ADVERTISEMENT", "ADJUSTMENT"
]
