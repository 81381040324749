<template>
  <div id="channel-link-footer" class="full-width-viewport">
    <el-row 
      :type="isMobile ? null : 'flex'" 
      :justify="isMobile ? 'center' : 'space-between'" 
      align="middle"
    >
      <el-col class="channel-row">
        <el-link 
          v-for="el in channelLinkList"
          :key="el.id"
          :href="el.code" 
          target="_blank"
        >{{ el | translate }}</el-link>
      </el-col>
      <el-col class="site-settings-row" :span="isMobile ? 24 : 10">
        <router-link 
          v-if="!isMobile && haveContactUs"
          to="/contact-us"
        >
          {{ $t('CONTACT_US') }}
        </router-link>

        <router-link 
          v-if="!isMobile && havePrivacyPolicy"
          to="/privacy-policy"
        >
          {{ $t('PRIVACY_POLICY') }}
        </router-link>

        <div class="social-media-wrapper">
          <img 
            v-for="el in socialMediaList"
            :key="el.id"
            class="custom-icon social-media-icon"
            :src="el.photo.upload.url"
            @click="() => goTo(el.name, true, true)"
          />
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { generalMixin } from "@/utils/general-mixin.js"
import { mapGetters } from "vuex";

export default {
  name: "ChannelLinkFooter",
  mixins: [ generalMixin ],
  computed: {
    ...mapGetters(["channelLinkList"]),
    ...mapGetters(["siteSettingList"]),
    haveContactUs() {
      const firstEl = this.siteSettingList.find(
        (el) => el.type === "contact_us"
      )
      return firstEl && firstEl.status === "ACTIVE"
    },
    havePrivacyPolicy() {
      const firstEl = this.siteSettingList.find(
        (el) => el.type === "privacy_policy"
      )
      return firstEl && firstEl.status === "ACTIVE"
    },
    socialMediaList() {
      return this.siteSettingList
      .filter((el) => (
        el.type === "social_media" 
        && el.status === "ACTIVE"
        && el.name !== ""
      ))
      .sort((a, b) => a.sequences - b.sequences)
    }
  }
}
</script>

<style lang="scss">
@import "@/assets/style/_variables.scss";

#channel-link-footer {
  background-color: #000000;
  position: absolute;
  bottom: $footerHeight;
  left: 0;
  height: $channelLinkHeight;
  width: 100%;
  z-index: 11;
  .el-row {
    height: 100%;
    margin: 0 auto;
    max-width: $viewMaxWidth;
    padding-left: $spacingLeftRight;
    padding-right: $spacingLeftRight;
    .el-link { 
      margin-right: 1rem; 
      color: #FFFFFF; 
      &:hover {
        color: #FF5B1C;
        &:after{
          border-bottom: 0;
        }
      }
    }
    .site-settings-row {
      display: table;
      a { 
        cursor: pointer;
        display: table-cell;
        vertical-align: middle;
        color: #FFFFFF;
        &:hover {
          color: #FF5B1C;
        }
        & + a {
          padding-left: 0.5rem;
          padding-right: 0.5rem;
        }
      }
      .social-media-wrapper {
        float: right;
      }
      .social-media-icon {
        cursor: pointer;
        width: 30px;
        height: 30px;
        background-size: 30px 30px;
      }
    }
  }
}

.mobile {
  #channel-link-footer {
    height: 8rem;
    bottom: $footerHeight;
    .channel-row {
      width: 100%;
      display: flex;
      justify-content: space-evenly;
      flex-wrap: wrap;
      margin-top: 1rem;
      height: 50%;
      overflow-y: scroll;
    }
    a { margin: 0.5rem }
    .site-settings-row {
      height: 50%;
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: 10px;
      .site-settings-row >span { margin-right: auto }
    }

  }
}
</style>