import request from "@/utils/request";


export async function getAllSiteSettings() {
  return await request({
    url: '/site-settings/get-all',
    method: "get"
  });
}


export async function getContactUsList() {
  const data = {
    genericRecord: {
      type: "contact_us"
    }
  }
  return await request({
    url: "/generic-record/list",
    method: "post",
    data
  })
}

export async function createContactUs(data) {
  return await request({
    url: '/generic-record/create',
    method: "post",
    data
  });
}

export async function updateContactUs(data) {
  return await request({
    url: '/generic-record/update',
    method: "post",
    data
  });
}


export async function getPrivacyPolicyList() {
  const data = {
    genericRecord: {
      type: "privacy_policy"
    }
  }
  return await request({
    url: "/generic-record/list",
    method: "post",
    data
  })
}

export async function createPrivacyPolicy(data) {
  return await request({
    url: '/generic-record/create',
    method: "post",
    data
  });
}

export async function updatePrivacyPolicy(data) {
  return await request({
    url: '/generic-record/update',
    method: "post",
    data
  });
}

export async function getSocialMediaList() {
  const data = {
    genericRecord: {
      type: "social_media"
    }
  }
  return await request({
    url: "/generic-record/list",
    method: "post",
    data
  })
}

export async function bulkUpdateSocialMedia(data) {
  return await request({
    url: '/generic-record/bulk-update',
    method: "post",
    data
  });
}