<template>
  <div v-if="isFromBO && !isMobile" id="sidebar">
    <div 
      class="sidebar-container"
    >
      <el-menu
        :default-active="activeTabId"
        class="sidebar-nav-menu"
        menu-trigger="click"
        v-loading="menuList.length === 0"
      >
        <el-menu-item 
          v-for="el in menuList" 
          :key="el.id" 
          :index="el.path"
          @auxclick.native="goTo(el.path, true)"
          @click="goTo(el.path)"
        >
          <span class="menu-item-container">
            <i class="custom-icon mini router-icon grey-icon" :class="el.icon"/> 
            {{ $t(el.nameCode) }}
          </span>
        </el-menu-item>
      </el-menu>
    </div>
  </div>
</template>

<script>
import { generalMixin } from "@/utils/general-mixin.js"
import { headerSidebarMixins } from "./header-sidebar-mixins"

export default {
  name: "Sidebar",
  mixins: [ generalMixin, headerSidebarMixins ],
  computed: {
  },
  data() {
    return {
    };
  },
  methods: {
  },
}
</script>

<style lang="scss">
@import "@/assets/style/_variables.scss";
@import "@/assets/style/mixin/general.scss";
@include icon-colors;

#sidebar {
  z-index: 101;
  min-width: $sidebarWidth;
  background-color: white;
  box-shadow: 10px 0px 15px #00000024;

  .sidebar-nav-menu {
    padding-left: $spacingLeftRight;
    padding-right: 1rem;
    padding-top: 10px;
    padding-bottom: 2rem;
    width: 100%;
    border-right: none;

    .el-menu-item {
      height: 45px;
      padding-left: 0 !important;
      padding-right: 0 !important;
      + .el-menu-item {
        border-top: 1px solid #e8e8e8;
      }

      &.is-active,
      &:hover {
        background-color: transparent;
        .custom-icon {
          @extend .white-icon;
        }
        .menu-item-container {
          background-color: $primary;
          color: white;
        }
      }
    }
  }
  .menu-item-container {
    display: flex;
    align-items: center;
    padding: 0.25rem;
    padding-left: 0;
    height: 100%;
    /* width: 100%; */
    border-radius: 10px;
  }
  .router-icon {
    margin: auto 0.5rem;
  }


}

</style>