import { EventBus } from "@/utils/event-bus.js"

var isTabActive = (function() {
  var stateKey,
    eventKey,
    keys = {
      hidden: "visibilitychange",
      webkitHidden: "webkitvisibilitychange",
      mozHidden: "mozvisibilitychange",
      msHidden: "msvisibilitychange"
    }
  for (stateKey in keys) {
    if (stateKey in document) {
      eventKey = keys[stateKey]
      break
    }
  }
  return function(c) {
    if (c) document.addEventListener(eventKey, c)
    return !document[stateKey]
  };
})();

isTabActive() // registers a handler for visibility changes`

isTabActive(function() {
  if (isTabActive()) {
    EventBus.$emit("toggleTabActive", true)
    document.title = process.env.VUE_APP_TITLE
  } else {
    EventBus.$emit("toggleTabActive", false)
    document.title = `${process.env.VUE_APP_TITLE} | Offline`
  }
})


// example to use
// EventBus.$on("toggleTabActive", isActive => {
//   console.log(isActive)
// });