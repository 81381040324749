import { mapState, mapGetters } from "vuex";
import {
  AVAIL_STATUS,
  SELECTABLE_STATUS,
  DATE_FORMAT,
  DATE_TIME_FORMAT,
  EL_DATE_FORMAT,
  EL_DATE_FORMAT_MOBILE,
  TIME_FORMAT,
  NOTIFY_POSITION,
  NOTIFY_DURATION,
} from "@/constants";
import moment from "moment";
import {
  fallbackCopyTextToClipboard,
  toDash,
} from "@/utils/helpers.js";

export const generalMixin = {
  data() {
    return {
      isEnvDev: process.env.VUE_APP_NODE_ENV == "development",
      DATE_FORMAT,
      DATE_TIME_FORMAT,
      EL_DATE_FORMAT,
      EL_DATE_FORMAT_MOBILE,
      TIME_FORMAT,
      AVAIL_STATUS,
      SELECTABLE_STATUS,
      NOTIFY_POSITION,
      NOTIFY_DURATION,
    };
  },
  computed: {
    ...mapState({
      isMobile: (state) => state.app.isMobile,
      locale: (state) => state.app.locale,
    }),
    ...mapGetters("app", ["getNameFromTransList"]),
    ...mapGetters([
      "isAuthenticated",
      "isAdmin",
      "isManager",
      "isOfficer",
      "isMemberOne",
      "isMemberTwo",
      "isMemberThree",
      "isMemberFour",
    ]),
    isFromPublic() {
      return this.$route.matched[0]?.path == "";
    },
    isFromBO() {
      return this.$route.matched[0]?.path == "/bo";
    },
    isFromBOAuth() {
      return this.$route.matched[0]?.path == "/auth";
    },
    isBOUser() {
      return this.isAdmin || this.isManager || this.isOfficer;
    },
    isMember() {
      return (
        this.isMemberOne ||
        this.isMemberTwo ||
        this.isMemberThree ||
        this.isMemberFour
      );
    },
    fullAccess() {
      return this.isAdmin || this.isManager;
    },
    viewAccess() {
      return this.isOfficer;
    },
  },
  methods: {
    handleError(errorMessage) {
      console.error(errorMessage);
    },
    // START: ARTICLE MODULE
    getMetaTitle(obj){
      return toDash(this.getNameFromTransList(obj.metaTitle))
    },
    goToArticle(articleObj, inNewTab = false) {
      const path = `/${this.getMetaTitle(articleObj)}`
      if (inNewTab) {
        const routeData = this.$router.resolve(path);
        window.open(routeData.href, "_blank");
      } else {
        this.$router.push(path).catch(() => {});
      }
    },
    goToPreview(obj,locale) {
      const title = this.getMetaTitle(obj)
      const path = `/${title}?locale=${locale}`
      window.open(path, "_blank");
    },
    articleURL(articleObj) {
      return `${window.location.origin}/${toDash(
        this.getNameFromTransList(articleObj.metaTitle)
      )}`;
    },
    copyArticleLink(article) {
      const link = this.articleURL(article);
      if (!navigator.clipboard) {
        fallbackCopyTextToClipboard(link);
      } else {
        navigator.clipboard.writeText(link);
      }
      this.$notify({
        message: this.$t("COPY_TO_CLIPBOARD"),
        duration: this.NOTIFY_DURATION,
        position: this.NOTIFY_POSITION,
      });
    },
    // END: ARTICLE MODULE
    goTo(path, inNewTab = false, isExternal = false) {
      if (isExternal) {
        const openTarget = inNewTab ? "_blank" : "_self";
        window.open(path, openTarget);
      } else {
        if (inNewTab) {
          const routeData = this.$router.resolve(path);
          window.open(routeData.href, "_blank");
        } else {
          this.$router.push(path).catch(() => {});
        }
      }
    },
    getStatusClass(status) {
      return AVAIL_STATUS.find((el) => el.value === status)?.class;
    },
    getFullName(user) {
      if (!user) return "-";
      const fullName = user.firstName + " " + user.lastName || "-";
      return fullName;
    },
    parseDate(timestamp) {
      if (timestamp == null) return null;
      return moment(timestamp).format(DATE_TIME_FORMAT);
    },
    canChangeStatus(value) {
      const found = SELECTABLE_STATUS.find((el) => el == value);
      return found != null && found != undefined;
    },
    allSelectableStatusListFor(value = null) {
      if (value == null || this.canChangeStatus(value)) {
        return AVAIL_STATUS.filter((el) => {
          return SELECTABLE_STATUS.find((el2) => el2 == el.value) != null;
        });
      } else {
        return AVAIL_STATUS; // display only
      }
    },
  },
  mounted() {},
};
