import request from "@/utils/request";

export async function login(authData) {
  const encodedAuthData = Buffer.from(authData.email + ":" + authData.password).toString('base64')

  return await request({
    url: '/user/login',
    method: "post",
    headers: {
      "Authorization": "Basic " + encodedAuthData
    }
  });
}

export async function logout() {
  return await request({
    url: '/user/logout',
    method: "post",
    // post must have data (even it is empty) to avoid Content-Type being deleted (HTTP error 415)
    data: {},
  });
}

export async function create(data) {
  return await request({
    url: '/user/create',
    method: "post",
    data,
  });
}

export async function forgotPassword(data) {
  return await request({
    url: '/user/reset-password',
    method: "post",
    data,
  });
}

export async function otpValidatation(data) {
  return await request({
    url: '/otp/check-validate',
    method: "post",
    data,
  });
}

export async function changePassword(data) {
  return await request({
    url: '/user/change-password',
    method: "post",
    data,
  });
}

export async function getCurrentUserProfile() {
  return await request({
    url: '/user/',
    method: "get",
  });
}

export async function updateCurrentProfile(data) {
  return await request({
    url: '/user/update',
    method: "post",
    data,
  });
}

