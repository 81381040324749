import { LAYOUT_DEFAULT } from "@/constants";
import PassThrough from "@/views/pass-through";
import { LazyLoadComponentWrapper } from "@/utils/lazy-load-component-wrapper";
import { ifAuthenticated } from "@/utils/auth";

const publicRouter = {
  path: "",
  component: PassThrough,
  meta: {
    layout: LAYOUT_DEFAULT,
  },
  children: [
    {
      path: "",
      name: "PublicMain",
      component: () => LazyLoadComponentWrapper(import("@/views/public/category")),
    },
    {
      path: "category/:categoryId",
      name: "PublicByCategory",
      component: () => LazyLoadComponentWrapper(import("@/views/public/category")),
    },
    {
      path: "search",
      name: "PublicBySearchKeyword",
      component: () => LazyLoadComponentWrapper(import("@/views/public/search")),
    },
    {
      path: "complain/:type/:id", // type = article, advertisement, p2p_transaction
      name: "PublicComplain",
      meta: { hideChannelLinkFooter: true },
      component: () => LazyLoadComponentWrapper(import("@/views/public/complain")),
    },
    {
      path: "faq",
      name: "FAQ",
      component: () => LazyLoadComponentWrapper(import("@/views/public/faq")),
    },
    {
      path: "contact-us",
      name: "Contact Us",
      component: () => LazyLoadComponentWrapper(import("@/views/public/contact-us")),
    },
    {
      path: "privacy-policy",
      name: "Privacy Policy",
      component: () => LazyLoadComponentWrapper(import("@/views/public/privacy-policy")),
    },
    {
      path: "favourite",
      name: "Favourite Article",
      meta: {
        breadcrumbTransCodeList: ["PROFILE", "FAVOURITE_ARTICLE"],
      },
      beforeEnter: ifAuthenticated,
      component: () => LazyLoadComponentWrapper(import("@/views/public/favourite")),
    },
    {
      path: ":title",
      name: "DetailPage",
      component: () => LazyLoadComponentWrapper(import("@/views/public/detail")),
    },
  ]
};

export default publicRouter;
