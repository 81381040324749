import ls from 'localstorage-slim';
import { getAllLanguage, stayAlive } from "@/api/app.js"
import { getCurrencyList } from "@/api/utility.js"
import { getChannelList } from "@/api/channel-link.js"
import { getAllSiteSettings } from "@/api/site-settings.js"
import { updateLanguage } from "@/api/user.js"
import { changeLibLang } from "@/locales"
import i18n from "@/locales"

const state = {
  sidebar: {
    opened: ls.get("sidebar-status") == 1,
    withoutAnimation: false
  },
  isMobile: false,
  langList: [],
  currencyList: [],
  channelLinkList: [],
  siteSettingList: [],
  locale: localStorage.getItem("language") || 'en',
  preLocale: 'en', //get lang from query for temporary use 
  t: null, // will be injected later in App.vue
  stayAliveInterval: null,
  channelList: []
};

const getters = {
  getLocales: (state) => () => state.langList.map(el => el.code),
  getLangList: (state) => () => {
    return state.langList
  },
  getSelectedLangLabel: (state) => () => {
    return state.langList.find(el => el.code == state.locale)?.label
  },
  getSelectableLangList: (state) => () => {
    return state.langList.filter(el => el.code != state.locale)
  },
  getCurrentLangFlagImg: (state) => () => {
    const locale = state.locale
    return state.langList.find(el => el.code == locale)?.photo?.upload?.url
  },
  getLangFlagImage: (state) => (code) => {
    return state.langList.find(el => el.code == code)?.photo?.upload?.url
  },
  getNameFromTransList: (state) => (translationListOrObj, emptyText = "", onlyLanguage = null) => {
    if (!translationListOrObj) return emptyText
    const type = typeof translationListOrObj
    if (type == 'string' || type == 'number') return translationListOrObj

    let translationList = translationListOrObj
    // the object passed in is not the list, get it
    if (!translationListOrObj.length) translationList = translationListOrObj.translationList
    if (!translationList) return emptyText

    let langObjFound = translationList.find(trans => trans.language?.code == (onlyLanguage || state.locale))
    if (onlyLanguage == null && (langObjFound == null || langObjFound.name == "")) { // default to en ONLY if onlyLanguage is not set
      langObjFound = translationList.find(trans => trans.language?.code == 'en')
      if (langObjFound == null) {
        langObjFound = translationList[0] // default en not available -> get the first element
      }
    }
    return langObjFound ? langObjFound.name : emptyText
  }
}

const actions = {
  setIsMobile({ commit }, isMobile) {
    commit('SET_IS_MOBILE', isMobile)
  },
  getAllLanguage({ commit }) {
    return new Promise((resolve, reject) => {
      getAllLanguage().then(res => {
          if (res.status == 'ok') {
            commit('SET_LANG_LIST', res.result)
            resolve(res.status)
          }
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getAllCurrency({ commit }) {
    return new Promise((resolve, reject) => {
      getCurrencyList().then(res => {
          if (res.status == 'ok') {
            commit('SET_CURRENCY_LIST', res.result.list)
            resolve(res.status)
          }
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getAllChannelLink({ commit }) {
    const postData = {
      genericRecord: {
        status: "ACTIVE",
        type: "channel_link"
      }
    }
    return new Promise((resolve, reject) => {
      getChannelList(postData).then(res => {
          if (res.status == 'ok') {
            commit('SET_CHANNEL_LINK_LIST', res.result.list)
            resolve(res.status)
          }
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getAllSiteSettings({ commit }) {
    return new Promise((resolve, reject) => {
      getAllSiteSettings().then(res => {
          if (res.status == 'ok') {
            commit('SET_SITE_SETTING_LIST', res.result)
            resolve(res.status)
          }
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  setLocale({ commit, state, rootGetters, rootState }, { value, updateBE = true }) {
    const langObj = state.langList.find(el => el.code == value)
    if (rootGetters.isAuthenticated && updateBE) {
      updateLanguage({ language: langObj })
    }
    i18n.locale = value
    changeLibLang(value)
    commit("SET_LOCALE", { locale: value, rootState, rootGetters })
  },
  setPreviewLocale({ commit, rootState }, value) {   
    i18n.locale = value
    changeLibLang(value)
    commit("SET_PREVIEW_LOCALE", { locale: value, rootState })
  },
  loopStayAlive({ dispatch, commit, state }) {
    if (state.stayAliveInterval != null) {
      dispatch('cancelLoopStayAlive')
    }

    const stayAliveInterval = setInterval(() => {
      dispatch('stayAlive')
    }, 60000)

    commit('SET_STAY_ALIVE_INTERVAL', stayAliveInterval)
  },
  stayAlive({ dispatch, rootGetters }) {
    if (!rootGetters.isAuthenticated) {
      dispatch('cancelLoopStayAlive')
      return
    }

    stayAlive().then(() => {
      console.log("(loop) calling stayAlive")
    })
  },
  cancelLoopStayAlive({ commit, state }) {
    clearInterval(state.stayAliveInterval)
    commit('SET_STAY_ALIVE_INTERVAL', null)
  },
  getChannelList({ commit }, data) {
    return new Promise((resolve, reject) => {
      getChannelList(data).then(res => {
          if (res.status == 'ok') {
            commit('SET_CHANNEL_LIST', res.result.list)
            resolve(res.result.list)
          }
        })
        .catch(err => {
          reject(err);
        });
    });
  },
};

const mutations = {
  SET_IS_MOBILE: (state, isMobile) => {
    state.isMobile = isMobile
  },
  SET_LOCALE(state, { locale, rootState, rootGetters }) {
    const localeList = rootGetters["app/getLocales"]() || []
    if (localeList.includes(locale)) {
      localStorage.setItem("language", locale)
      state.locale = locale
      rootState.app.t.locale = locale
    }
  },
  SET_PREVIEW_LOCALE(state, { locale, rootState }) {
    if (state.preLocale.includes(locale)) {
      state.preLocale = locale
      rootState.app.t.preLocale = locale
    }
  },
  SET_LANG_LIST: (state, langList) => {
    state.langList = langList
  },
  SET_CURRENCY_LIST: (state, currList) => {
    state.currencyList = currList
  },
  SET_CHANNEL_LINK_LIST: (state, list) => {
    state.channelLinkList = list
  },
  SET_SITE_SETTING_LIST: (state, list) => {
    state.siteSettingList = list
  },
  SET_TRANSLATION_FN: (state, t) => {
    state.t = t
  },
  SET_STAY_ALIVE_INTERVAL: (state, stayAliveInterval) => {
    state.stayAliveInterval = stayAliveInterval
  },
  SET_CHANNEL_LIST: (state, data) => {
    state.channelList = data
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
