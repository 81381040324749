const getters = {
  sidebar: state => state.app.sidebar,
  device: state => state.app.device,
  locale: state => state.app.locale,
  token: state => state.auth.token,
  user: state => state.auth.user,
  roleName: state => state.auth.roleName,
  isMemberOne: state => state.auth.roleName == "member_level_one",
  isMemberTwo: state => state.auth.roleName == "member_level_two",
  isMemberThree: state => state.auth.roleName == "member_level_three",
  isMemberFour: state => state.auth.roleName == "member_level_four",
  isAdmin: state => state.auth.roleName == "admin",
  isManager: state => state.auth.roleName == "manager",
  isOfficer: state => state.auth.roleName == "officer",
  isAuthenticated: state => !!state.auth.token,
  t: state => state.app.t,
  isBankDetailComplete: state => {
    return state.auth.user.bankName
          && state.auth.user.bankAccountNum
          && state.auth.user.bankAccountHolderName
  },
  currencyList: state => state.app.currencyList,
  channelLinkList: state => state.app.channelLinkList,
  siteSettingList: state => state.app.siteSettingList,
  isBOUser: state => {
    return state.auth.roleName == "admin" || state.auth.roleName == "manager" || state.auth.roleName == "officer"
  }
}
export default getters