<template>
  <el-dropdown
    class="lang-dropdown"
    :class="{
      'is-in-menu': isInMenu
    }"
    :placement="isInMenu ? 'bottom' : 'bottom-end'"
    :size="isInMenu ? 'medium' : ''"
    @command="setLocale"
    :hide-on-click="false"
  >
    <template v-if="isInMenu">
      <el-row type="flex" justify="space-between" align="middle">
        <el-col :span="22">
          <el-row type="flex" justify="start" align="middle">
            <img class="custom-icon icon-lang" :src="getCurrentLangFlagImg()" />
            <span class="lang-selected">{{ getSelectedLangLabel() }}</span>
          </el-row>
        </el-col>
        <el-col :span="2">
          <i class="icon-arrow-down custom-icon white-icon" />
        </el-col>
      </el-row>
    </template>

    <template v-else>
      <span class="el-dropdown-link pointer">
        <img class="custom-icon icon-lang" :src="getCurrentLangFlagImg()" />
        <i class="icon-arrow-down custom-icon" />
        <span class="lang-selected">{{ getSelectedLangLabel() }}</span>
      </span>
    </template>


    <el-dropdown-menu 
      slot="dropdown"
      class="menu-popper"
      :class="{ 'is-in-menu': isInMenu }" 
    >
      <el-dropdown-item
        v-for="el in getSelectableLangList()"
        :key="el.code"
        :command="el.code"
        >
          <template v-if="isInMenu">
            <img class="custom-icon icon-lang" :src="el.photo.upload.url" />
          </template>
          <span class="label-lang">{{ el.label }}</span>
        </el-dropdown-item>
    </el-dropdown-menu>
  </el-dropdown>
</template>

<script>
import { mapGetters } from "vuex";
import { generalMixin } from "@/utils/general-mixin.js"
import { EventBus } from "@/utils/event-bus";

export default {
  name: "LanguageSelection",
  mixins: [ generalMixin ],
  computed: {
    ...mapGetters("app", ["getSelectedLangLabel", "getSelectableLangList", "getCurrentLangFlagImg", "getNameFromTransList"]),
  },
  props: {
    isInMenu: { type: Boolean, default: false }
  },
  data() {
    return {
    };
  },
  methods: {
    // SHARED
    setLocale(locale) {
      this.$store.dispatch('app/setLocale', { value: locale, updateBE: true });
      (EventBus || this.$EventBus).$emit("on-language-change", locale);
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/style/_variables.scss";

.lang-dropdown {
  margin: auto 10px;
  display: inline-flex;
  align-items: center;
  font-size: 14px;
  .el-dropdown-link {
    display: flex;
    align-items: center;
  }
  &:not(&.is-in-menu) {
    .icon-arrow-down {
      background-size: 25px 25px;
      height: 25px;
      width: 25px;
    }
  }
  .icon-lang {
    height: 21px;
    width: 28px;
    background-size: 28px 21px;
  }

  &.is-in-menu {
    margin-right: 0;
    display: block;
    color: $white;
    background-color: $primary;
    .lang-selected {
      margin-left: 10px;
    }
    .icon-arrow-down {
      float: right;
    }
  }
}

.menu-popper {
  &.is-in-menu {
    width: calc(100vw - 40px);
    border: none;
    padding-top: 0;
    padding-bottom: 0;
    background-color: $primary;
    margin-top: -3px;
    margin-bottom: -3px;
    box-shadow: none;
    border: 1px solid white;

    .popper__arrow { display: none; }
    .icon-lang {
      margin-right: 0;
      vertical-align: middle;
      height: 21px;
      width: 28px;
      background-size: 28px 21px;
    }
    .el-dropdown-menu__item {
      padding: 0;
      margin: 5px 10px;
    }
    .label-lang {
      margin-left: 10px;
      color: $white;
    }
  }
}
</style>