import { LAYOUT_BO } from "@/constants";
import { LazyLoadComponentWrapper } from "@/utils/lazy-load-component-wrapper";
import { ifAuthenticated } from "@/utils/auth";

const articleRouter = {
  path: "profile",
  component: () => LazyLoadComponentWrapper(import("@/views/bo/user/detail.vue")),
  hideFromWebMenu: true,
  beforeEnter: ifAuthenticated,
  meta: {
    menuTitleTransCode: "PROFILE",
    breadcrumbTransCodeList: ["PROFILE"],
    layout: LAYOUT_BO,
    menuIcon: "icon-login-profile",
    allowedRoles: ["ADMIN", "MANAGER", "OFFICER", "MEMBER"]
  },
};

export default articleRouter;
