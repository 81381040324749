import { LAYOUT_BO } from "@/constants";
import { LazyLoadComponentWrapper } from "@/utils/lazy-load-component-wrapper";
import { ifAuthenticated } from "@/utils/auth";
import PassThrough from "@/views/pass-through";

const categoryRouter = {
  path: "category",
  component: PassThrough,
  beforeEnter: ifAuthenticated,
  meta: {
    layout: LAYOUT_BO,
    menuTitleTransCode: "CATEGORY",
    menuIcon: "icon-route-category",
    allowedRoles: ["ADMIN", "MANAGER", "OFFICER"]
  },
  children: [
    {
      path: "",
      component: () => LazyLoadComponentWrapper(import("@/views/bo/category/list.vue")),
      meta: {
        layout: LAYOUT_BO,
        breadcrumbTransCodeList: ["CATEGORY_MANAGEMENT"],
      },
    },
    {
      path: "detail/:id(\\d+)?",
      component: () => LazyLoadComponentWrapper(import("@/views/bo/category/detail.vue")),
      meta: {
        layout: LAYOUT_BO,
        breadcrumbTransCodeList: ["CATEGORY_MANAGEMENT", "CATEGORY_MANAGEMENT_DETAIL"],
      },
    }
  ]
};

export default categoryRouter;
