import { LAYOUT_BO } from "@/constants";
import { LazyLoadComponentWrapper } from "@/utils/lazy-load-component-wrapper";
import { ifAuthenticated } from "@/utils/auth";

const notificationRouter = {
  path: "notification",
  component: () => LazyLoadComponentWrapper(import("@/views/bo/notification/list.vue")),
  beforeEnter: ifAuthenticated,
  hideFromWebMenu: true,
  meta: {
    menuTitleTransCode: "NOTIFICATION",
    breadcrumbTransCodeList: ["NOTIFICATION"],
    layout: LAYOUT_BO,
    menuIcon: "icon-route-notification",
    allowedRoles: ["ADMIN", "MANAGER", "OFFICER", "MEMBER"]
  },
};

export default notificationRouter;
