import store from "@/store";

import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n);

export default new VueI18n({
  locale: store?.getters?.locale || 'en', // set locale
  fallbackLocale: 'en',
  messages: {
    en: require('./en.json'),
    zh: require('./zh.json'),
    ms: require('./ms.json'),
    hi: require('./hi.json'),
    bn: require('./bn.json'),
    ta: require('./ta.json'),
    ur: require('./ur.json'),
    vi: require('./vi.json'),
    th: require('./th.json'),
    id: require('./id.json'),
  }
});

// Third party localization (moment + element-ui)
import bn from 'element-ui/lib/locale/lang/bn'
import en from 'element-ui/lib/locale/lang/en'
import hi from 'element-ui/lib/locale/lang/ta' // hindi == tamil, if not, find similar here https://element.eleme.io/#/en-US/component/i18n
import ms from 'element-ui/lib/locale/lang/id' // malay == indonesian, if not, find similar here https://element.eleme.io/#/en-US/component/i18n
import zh from 'element-ui/lib/locale/lang/zh-TW'
import ta from 'element-ui/lib/locale/lang/ta'
import ur from 'element-ui/lib/locale/lang/ta' // Urdu shares its origins with Hindi
import vi from 'element-ui/lib/locale/lang/vi'
import th from 'element-ui/lib/locale/lang/th'
import id from 'element-ui/lib/locale/lang/id'
import locale from 'element-ui/lib/locale'

import moment from "moment"

export const changeLibLang = (value) => {
  switch(value) {
    case "bn": {
      locale.use(bn)
      moment.locale("bn")
      break
    }
    case "en": {
      locale.use(en)
      moment.locale("en")
      break
    }
    case "hi": {
      locale.use(hi)
      moment.locale("hi")
      break
    }
    case "ms": {
      locale.use(ms)
      moment.locale("ms")
      break
    }
    case "zh": {
      locale.use(zh)
      moment.locale("zh-TW")
      break
    }
    case "ta": {
      locale.use(ta)
      moment.locale("ta")
      break
    }
    case "ur": {
      locale.use(ur)
      moment.locale("ur")
      break
    }
    case "vi": {
      locale.use(vi)
      moment.locale("vi")
      break
    }
    case "th": {
      locale.use(th)
      moment.locale("th")
      break
    }
    case "id": {
      locale.use(id)
      moment.locale("id")
      break
    }
  }
}


