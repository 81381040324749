import { LAYOUT_BO } from "@/constants";
import { ifAuthenticated } from "@/utils/auth";
import { LazyLoadComponentWrapper } from "@/utils/lazy-load-component-wrapper";
import PassThrough from "@/views/pass-through";

const advertisementRouter = {
  path: "advertisement",
  component: PassThrough,
  beforeEnter: ifAuthenticated,
  meta: {
    layout: LAYOUT_BO,
    menuTitleTransCode: "ADVERTISE_MANAGEMENT",
    menuIcon: "icon-route-advertisement",
    allowedRoles: ["ADMIN", "MANAGER", "OFFICER", "MEMBER"]
  },
  children: [
    {
      path: "",
      component: () => LazyLoadComponentWrapper(import("@/views/bo/advertisement/index.vue")),
      meta: {
        layout: LAYOUT_BO,
        breadcrumbTransCodeList: ["ADVERTISE_MANAGEMENT"],
      },
    },
    {
      path: "detail/:articleId/:advertisementId/:approvalId",
      component: () => LazyLoadComponentWrapper(import("@/views/bo/advertisement/detail.vue")),
      meta: {
        layout: LAYOUT_BO,
        breadcrumbTransCodeList: ["ADVERTISE_MANAGEMENT", "ADS_DETAIL"],
      },
    }
  ]
};

export default advertisementRouter;
