import { getEarliestTransactionTimestamp } from "@/api/p2p-market.js"
import { getRemainingTime } from "@/utils/helpers.js"

const state = {
  latestTimeout: null,
  lessThan1Min: false,
  loopInterval: null,
  timeoutInterval: null,
  redirectBackTo: false
};

const actions = {
  loopGetEarliestTransactionTimestamp({ dispatch, commit, state }) {
    dispatch('getEarliestTransactionTimestamp') // call once initially

    if (state.loopInterval != null) {
      dispatch('cancelLoopGetEarliestTransactionTimestamp')
    }

    const loopInterval = setInterval(() => {
      dispatch('getEarliestTransactionTimestamp')
    }, 5000)

    commit('SET_LOOP_INTERVAL', loopInterval)
  },
  getEarliestTransactionTimestamp({ dispatch, rootGetters }) {
    if (!rootGetters.isAuthenticated) {
      dispatch('cancelLoopGetEarliestTransactionTimestamp')
      return
    }
    getEarliestTransactionTimestamp().then(res => {
      // console.log("(loop) calling getEarliestTransactionTimestamp")
      if (res.status == "ok" && res.result > 0) {
        dispatch("loopTimeout", res.result)
      } else {
        dispatch("cancelLoopTimeout")
      }
    })
  },
  cancelLoopGetEarliestTransactionTimestamp({ commit, state, dispatch }, cancelLoopTimeout = true) {
    clearInterval(state.loopInterval)
    commit('SET_LOOP_INTERVAL', null)

    if (cancelLoopTimeout) dispatch("cancelLoopTimeout")
  },

  loopTimeout({ dispatch, commit }, value) {
    if (state.timeoutInterval != null) {
      clearInterval(state.timeoutInterval)
      commit('SET_TIMEOUT_INTERVAL', null)
    }

    const timeoutInterval = setInterval(() => {
      dispatch("parseTimeout", value)
    }, 1000)
    commit('SET_TIMEOUT_INTERVAL', timeoutInterval)
  },
  cancelLoopTimeout({ commit, state }) {
    clearInterval(state.timeoutInterval)
    commit('SET_TIMEOUT_INTERVAL', null)
    commit("SET_LESS_THAN_ONE_MIN", false)

    commit('SET_LATEST_TIMEOUT', null)
  },
  parseTimeout({ commit, dispatch }, value) {
    const timeObj = getRemainingTime(value, 15*60)

    if (timeObj.remainingInSec < 0) {
      dispatch("cancelLoopTimeout")
    } else {
      commit("SET_LATEST_TIMEOUT", timeObj.formatted)
      if (timeObj.isLessThan1Min) {
        commit("SET_LESS_THAN_ONE_MIN", true)
      } else {
        commit("SET_LESS_THAN_ONE_MIN", false)
      }
    }
  },
};

const mutations = {
  SET_LATEST_TIMEOUT: (state,  latestTimeout) => {
    state.latestTimeout = latestTimeout
  },
  SET_LOOP_INTERVAL: (state, loopInterval) => {
    state.loopInterval = loopInterval
  },
  SET_TIMEOUT_INTERVAL: (state, timeoutInterval) => {
    state.timeoutInterval = timeoutInterval
  },
  SET_LESS_THAN_ONE_MIN: (state, lessThan1Min) => {
    state.lessThan1Min = lessThan1Min
  },
  REDIRECT_BACK_TO: (state, path) => {
    state.redirectBackTo = path
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
