<template>
  <div id="breadcrumb">
    <h1 v-if="!isMobile" class="text-grey">
      {{ currentPageName }}
    </h1>
    <div class="greeting-text text-grey" v-if="isDashboard">
      {{ $t("GREETING", { name: firstName }) }}
    </div>
    <el-breadcrumb v-else separator="|">
      <el-breadcrumb-item v-for="el in breadcrumbList" :key="el.name">
        {{ el.name }}
      </el-breadcrumb-item>
    </el-breadcrumb>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Breadcrumb",
  props: {
    isPublic: { type: Boolean, default: false },
  },
  computed: {
    ...mapState('app', ['isMobile']),
    breadcrumbList() {
      let result = []
      this.$route.matched.forEach(el => {
        if (el.meta?.breadcrumbTransCodeList != null && el.meta?.hideBreadcrumb != true) {
          el.meta.breadcrumbTransCodeList.forEach(code => {
            result.push({
              name: this.$t(code)
            })
          })
        }
      })

      if (this.isPublic !== true) {
        result.unshift({
          name: this.$t("DASHBOARD")
        })
      }

      return result
    },
    currentPageName() {
      return this.breadcrumbList[this.breadcrumbList.length-1].name
    },
    isDashboard() {
      return this.$route.path == '/bo/dashboard'
    },
    firstName() {
      return this.$store.state.auth.user.firstName
    }
  },
}
</script>

<style lang="scss">
@import "@/assets/style/_variables.scss";

#breadcrumb {
  position: relative;
  top: 20px;
  left: 0;
  height: $breadcrumbHeight + 20px;
  padding: $spacingLeftRight;
  padding-top: 0.5rem;
  max-width: $viewMaxWidth;
  margin: auto;

  h1 {
    font-size: 30px
  }
  .greeting-text,
  .el-breadcrumb {
    color: #767396;
    font-size: 15px;
    margin-top: 0.05rem;
  }
  .greeting-text {
    width: 23rem
  }

  .el-breadcrumb__item:last-child {
    span {
      font-weight: bold;
    }
  }
}

.mobile {
  #breadcrumb {
    top: 0;
    height: fit-content;
    padding: $spacingLeftRightMobile;
    h1 {
      font-size: 20px;
    }
    .greeting-text,
    .el-breadcrumb {
      font-size: 15px;
      line-height: 20px;
    }
  }
}
</style>