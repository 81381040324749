import { login, logout, getCurrentUserProfile } from "@/api/auth";
import ls from 'localstorage-slim';
import { Notification } from 'element-ui'
import router from '@/router'
import cloneDeep from "lodash/cloneDeep"
import { NOTIFY_DURATION, NOTIFY_POSITION } from "@/constants"

let existingToken = ""

try {
  existingToken = ls.get("user-token", { decrypt: true })
} catch(err) {
  console.error(err)
  // might be token is not encrypted (from other places)
  // so, CLEAR them
  ls.clear()
  console.warn('Cleared local storage')
}

const state = {
  token: existingToken,
  user: {},
  roleName: "",
  oldPW: null,
  menuList: [],
  forcedLogoutOnce: false, // to prevent multiple force logout
  prevPath: null, // after user forced logout, return to the path he initially came
};

// private function
function filterAllowedRoute(list, roleName) {
  return cloneDeep(list)
  .filter(el => {
    if (el.meta?.allowedRoles != null) {
      return el.meta?.allowedRoles?.find(el => {
        if (el === "MEMBER") return roleName.includes(el)
        else return el === roleName
      }) != null
    } else {
      return true
    }
  })
  .map(el => {
    if (el.children?.length > 0) {
      el.children = filterAllowedRoute(el.children, roleName)
    }
    return el
  })
}

const actions = {
  login({ commit }, authData) {
    return new Promise((resolve, reject) => {
      login(authData).then(res => {
          if (res.status != 'error') {
            const data = res.result
            const roleName = data.role?.code;
            // /*FOR TESTING FIRST TIME LOGIN*/ data.firstTimeLogin = true

            commit('SET_TOKEN', data.token)
            commit('SET_USER', data)
            commit('SET_ROLE_NAME', roleName)
            if (data.firstTimeLogin) {
              commit('SET_OLD_PW', authData.password)
            }
            
            ls.set('user-token', data.token, { encrypt: true })
            resolve(res)
          } else throw Error(res)
        })
        .catch(err => {
          ls.remove("user-token")
          ls.remove("first-name")
          reject(err);
        });
    });
  },
  getCurrentUserProfile({ commit, rootState, rootGetters }) {
    return new Promise((resolve, reject) => {
      getCurrentUserProfile()
      .then(res => {
        if (res.status == 'ok') {
          const data = res.result
          commit('SET_USER', data)
          commit('SET_ROLE_NAME', data.role?.code)
          if (data.language?.code != null) {
            commit("app/SET_LOCALE", { locale: data.language.code, rootState, rootGetters }, { root: true })
          }
          resolve(data)
        } else throw Error(res)
      })
      .catch(err => {
        reject(err)
      })
    });
  },
  logout({ commit, rootGetters, dispatch }) {
    // if shouldRedirect == true, handle redirect
    return new Promise(resolve => {
        logout().finally(() => {
          if (rootGetters.token) {
            ls.remove("user-token");
            ls.remove("first-name")
            commit('SET_TOKEN', null)
            commit('SET_USER', {})
            commit('SET_ROLE_NAME', {})
          }

          dispatch("notification/cancelLoopCheckNotification", null, { root: true })

          resolve();
        })
    });
  },
  setToken({ commit }, token) {
    commit('SET_TOKEN', token)
    if (token == null || token == "") {
      ls.remove('user-token')
    } else {
      ls.set('user-token', token, { encrypt: true })
    }
  },
  forceLogout({ dispatch, commit, state, rootState }) {
    if (state.forcedLogoutOnce == true) return
    commit('SET_FORCED_LOGOUT_ONCE', true)

    if (state.token != null && state.token != "") {
      commit('SET_PREV_PATH', router.currentRoute.path)
  
      dispatch('logout', false).finally(() => {
        Notification({
          type: "error",
          message: rootState.app.t("FORCED_LOGOUT"),
          duration: NOTIFY_DURATION,
          position: NOTIFY_POSITION
        })
        router.go() // refresh the page to kick user out if in any page need authentication
      })

    }
  },
  getAllowedRoutes({ rootGetters }) {
    let roleName = "MEMBER"
    if (typeof rootGetters.roleName == "string") {
      roleName = rootGetters.roleName?.toUpperCase()
      if (roleName === "GLN-ADMIN") roleName = "ADMIN"
    }
    return filterAllowedRoute(router.options.routes, roleName)
  }
};

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_USER: (state, user) => {
    state.user = user
  },
  SET_ROLE_NAME: (state, roleName) => {
    state.roleName = roleName
  },
  SET_FORCED_LOGOUT_ONCE: (state, value) => {
    state.forcedLogoutOnce = value
  },
  SET_PREV_PATH: (state, value) => {
    state.prevPath = value
  },
  SET_OLD_PW: (state, value) => {
    state.oldPW = value
  },
  SET_MENU_LIST: (state, value) => {
    state.menuList = value
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations
};
