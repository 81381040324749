import store from "../store";
import { Notification } from 'element-ui'
import { NOTIFY_DURATION, NOTIFY_POSITION } from "@/constants"

export const ifNotAuthenticated = (to, from, next) => {
  if (!store.getters.isAuthenticated) {
    next();
    return;
  }
  next("/");
};

export const ifAuthenticated = async (to, from, next) => {
  if (store.getters.isAuthenticated) {
    if (store.getters.roleName == "") { // havent got the user profile to check access, go fetch!
      await store.dispatch("auth/getCurrentUserProfile")
    }
    const roleName = store.getters.roleName.toUpperCase()
    let allowedTo = to.meta?.allowedRoles?.find(el => {
      if (el === "MEMBER") return roleName.includes(el)
      else return el === roleName
    }) != null

    if (to.meta?.allowedRoles == null || allowedTo) {
      next()
      return
    } else {
      next('/404')
      return
    }
  }
  next("/auth/sign-in");

  next()
};

export const P2PGuard = async (to, from, next) => {
  let canProceed = false
  await ifAuthenticated(to, from, (value) => {
    if (!value) canProceed = true
  })

  if (canProceed) {
    // BO user can skip checking of any bank details information
    if (store.getters.isBankDetailComplete || store.getters.isBOUser) {
      next()
    } else {
      store.commit("p2p-market/REDIRECT_BACK_TO", to.fullPath)
      Notification({
        type: "error",
        message: store.getters.t("ERROR_BANK_INCOMPLETE"),
        duration: NOTIFY_DURATION,
        position: NOTIFY_POSITION
      })
      next("/bo/profile");
    }
  }
};
