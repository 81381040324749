import { LAYOUT_BO } from "@/constants";
import { LazyLoadComponentWrapper } from "@/utils/lazy-load-component-wrapper";
import { ifAuthenticated } from "@/utils/auth";
import PassThrough from "@/views/pass-through";

const dashboardRouter = {
  path: "dashboard",
  component: PassThrough,
  beforeEnter: ifAuthenticated,
  meta: {
    layout: LAYOUT_BO,
    menuTitleTransCode: "DASHBOARD",
    hideBreadcrumb: true,
    menuIcon: "icon-route-dashboard",
    allowedRoles: ["ADMIN", "MANAGER", "OFFICER", "MEMBER"]
  },
  children: [
    {
      path: "",
      component: () => LazyLoadComponentWrapper(import("@/views/bo/dashboard/index.vue")),
      meta: {
        layout: LAYOUT_BO,
        menuTitleTransCode: "DASHBOARD",
        hideBreadcrumb: true,
      },
    }
  ]
};

export default dashboardRouter;
