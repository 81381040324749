import { mapGetters, mapState } from "vuex"
import { EventBus } from "@/utils/event-bus.js"

export const headerSidebarMixins = {
  watch: {
    isAuthenticated() { this.getBOMenu() }
  },
  methods: {
    getBOMenu() {
      this.$store.dispatch("auth/getAllowedRoutes")
      .then(result => {
        const allBO = result.find(el => el.path == '/bo')?.children
        const menuList = allBO.filter(el => this.isMobile ? true : el.hideFromWebMenu != true)
          .map(el => {
            return {
              nameCode: el.meta.menuTitleTransCode,
              icon: el.meta.menuIcon,
              path: "/bo/"+el.path
            }
          })
        if (this.isMobile) {
          menuList.unshift({
            nameCode: "NEWS_HOME",
            icon: "icon-home",
            path: "/"
          })
        }
        this.$store.commit("auth/SET_MENU_LIST", menuList)
      })
    },
  },
  computed: {
    ...mapGetters(["isAuthenticated"]),
    ...mapState('auth', ['menuList']),
    activeTabId() {
      let result = ""
      if (this.isFromPublic) {
        let categoryId = this.$route.params?.categoryId
        if (categoryId != null) {
          result = categoryId.toString()
        } else if (this.$route.fullPath == "/") {
          result = "0"
        } else {
          result = null
        }
        
      } else if (this.isFromBO) {
        result = this.$route.matched[1]?.path
      }
      return result
    },
  },
  mounted() {
    this.getBOMenu()
    EventBus.$on("success-get-profile", () => {
      this.getBOMenu()
    });
  }
}