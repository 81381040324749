import { LAYOUT_BO } from "@/constants";
import { LazyLoadComponentWrapper } from "@/utils/lazy-load-component-wrapper";
import { ifAuthenticated } from "@/utils/auth";

const settingsRouter = {
  path: "settings",
  component: () => LazyLoadComponentWrapper(import("@/views/bo/settings/index.vue")),
  beforeEnter: ifAuthenticated,
  meta: {
    layout: LAYOUT_BO,
    menuTitleTransCode: "SETTINGS",
    breadcrumbTransCodeList: ["SETTINGS"],
    menuIcon: "icon-route-settings",
    allowedRoles: ["ADMIN", "MANAGER", "OFFICER"]
  },
};

export default settingsRouter;
