// import app from "../main";
import axios from "axios";
import { Notification } from "element-ui";
import store from "@/store";
import { API_URL, DEFAULT_TIMEOUT, NOTIFY_DURATION, NOTIFY_POSITION } from "@/constants";

// create an axios instance
const config = {
  baseURL: API_URL,
  timeout: DEFAULT_TIMEOUT, // request timeout
}
const service = axios.create(config);
const serviceWithoutAxiosError = axios.create(config)

let errorMsgDisplayedOnce = []
const hiddenErrorCodeMsg = ["request.error.token.invalid"]

// request interceptor
const reqSuccessInterceptor = serviceConfig => {
  let locale = store.getters.locale
  if (locale == null) locale = 'en'
  let token = store.getters.token
  
  serviceConfig.headers["Content-Type"] = "application/json";
  serviceConfig.headers.common["locale"] = locale;
  serviceConfig.headers.common["app"] = "false";
  
  if (token) {
    serviceConfig.headers.common["Authorization"] = "Bearer " + token;
  } else {
    delete axios.defaults.headers.common["Authorization"];
  }

  // console.log(serviceConfig)
  return serviceConfig;
}
const reqErrorInterceptor = (error) => {
  // do something with request error
  console.error(error); // for debug
  Promise.reject(error);
}
service.interceptors.request.use(
  reqSuccessInterceptor, 
  reqErrorInterceptor
);
serviceWithoutAxiosError.interceptors.request.use(
  reqSuccessInterceptor
)

// response interceptor
const resSuccessInterceptor = (response) => {
  const res = response;
  const errorCode = res.data.errorCode
  const errorMsg = res.data.warningMessage || res.data.errorMessage || "Error"
  // console.log(res)
  if (res.status === 200 && res.data.status === "ok") {
    return res.data;
  } else {
    console.error(response); // for debug
    if (
      errorCode == "request.error.token.invalid" ||
      errorCode == "request.error.no.access"
    ) {
      // Already logged out, no need ask user to log out again
      if (res.config.url == "/user/logout") return;

      store.dispatch("auth/forceLogout");
    }
    if (errorMsgDisplayedOnce.length == 0 || errorMsgDisplayedOnce.find(el => el == errorMsg) == null) {
      // only display once for each errorMsg
      if (hiddenErrorCodeMsg.find(el => el == errorCode) == null) {
        Notification({
          message: errorMsg,
          type: "error",
          duration: NOTIFY_DURATION,
          position: NOTIFY_POSITION
        });
      }
    }
    errorMsgDisplayedOnce.push(errorMsg)
    setTimeout(() => {
      errorMsgDisplayedOnce = errorMsgDisplayedOnce.filter(el => el != errorMsg)
    }, 1000)
    return res.data;
  }
}

const resFailedInterceptor = (error) => {
  console.error(error); // for debug
  if (error.response?.status === 401) {
    store.dispatch("auth/forceLogout");
  } else {
    Notification({
      message: error.message,
      type: "error",
      duration: NOTIFY_DURATION,
      position: NOTIFY_POSITION
    });
    return Promise.reject(error);
  }
}

service.interceptors.response.use(
  resSuccessInterceptor,
  resFailedInterceptor
);
serviceWithoutAxiosError.interceptors.response.use(
  resSuccessInterceptor
)



// NOTE: POST must have data (even it is empty) to avoid Content-Type being deleted (HTTP error 415)

export default service;
export const requestWithoutAxiosError = serviceWithoutAxiosError;
