import request from "@/utils/request";

export async function getAdsPositionList() {
  return await request({
    url: '/generic-record/list',
    method: "post",
    data: {
      genericRecord: { type: "advertisement_banner_position" }
    }
  });
}

//PURCHASED LIST & ADS LIST
export async function getAdsList(postData) {
  return await request({
    url: "/advertisement/list",
    method: "post",
    data: postData,
  });
}
export async function archiveAds(id) {
  return await request({
    url: "/advertisement/"+id,
    method: "delete",
  });
}
export async function addAds(postData) {
  return await request({
    url: "/advertisement/create",
    method: "post",
    data: postData,
  });
}
export async function getAdsbyId(id) {
  return await request({
    url: "/advertisement/"+id,
    method: "get",
  });
}
export async function updateAds(postData) {
  return await request({
    url: "/advertisement/update",
    method: "post",
    data: postData,
  });
}

// PRICE SETUP
export async function getAdsPriceList(postData) {
  return await request({
    url: "/advertisement-price/list",
    method: "post",
    data: postData,
  });
}
export async function getAdsPriceById(id) {
  return await request({
    url: "/advertisement-price/"+id,
    method: "get",
  });
}
export async function updateAdsPrice(postData) {
  return await request({
    url: "/advertisement-price/bulk-update",
    method: "post",
    data: postData,
  });
}

// PENDING APPROVAL
export async function getPendingAdsList(postData) {
  return await request({
    url: "/approval/list",
    method: "post",
    data: postData,
  });
}
export async function updateAdsApproval(postData) {
  return await request({
    url: "/approval/update",
    method: "post",
    data: postData,
  });
}
