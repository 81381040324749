import { LAYOUT_BO } from "@/constants";
import { LazyLoadComponentWrapper } from "@/utils/lazy-load-component-wrapper";
import { ifAuthenticated } from "@/utils/auth";
import PassThrough from "@/views/pass-through.vue";

const coinHistoryRouter = {
  path: "coin-history",
  component: PassThrough,
  beforeEnter: ifAuthenticated,
  meta: {
    layout: LAYOUT_BO,
    menuTitleTransCode: "COIN_HISTORY",
    menuIcon: "icon-route-coin-history",
    allowedRoles: ["MEMBER"]
  },
  children: [
    {
      path: "",
      component: () => LazyLoadComponentWrapper(import("@/views/bo/coin-history/list.vue")),
      meta: {
        layout: LAYOUT_BO,
        breadcrumbTransCodeList: ["COIN_HISTORY"],
        allowedRoles: ["MEMBER"]
      },
    }
  ]
};

export default coinHistoryRouter;
