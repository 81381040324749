import request from "@/utils/request";
import { requestWithoutAxiosError } from "@/utils/request"

export async function getAllLanguage() {
  return await request({
    url: '/language/list',
    method: "get",
  });
}

export async function stayAlive() {
  return await requestWithoutAxiosError({
    url: '/attendance-record/stay-alive',
    method: "get",
  });
}