import request from "@/utils/request";
import { requestWithoutAxiosError } from "@/utils/request"


export async function getPostList(postData) {
  return await request({
    url: '/p2p-post/list',
    method: "post",
    data: postData
  });
}
export async function getPostById(id) {
  return await request({
    url: `/p2p-post/${id}`,
    method: "get",
  });
}

export async function createPost(postData) {
  return await request({
    url: '/p2p-post/create',
    method: "post",
    data: postData,
  });
}

export async function updatePost(postData) {
  return await request({
    url: '/p2p-post/update',
    method: "post",
    data: postData,
  });
}

export async function getActivePostByLogin(postData) {
  return await request({
    url: '/p2p-post/active',
    method: "post",
    data: postData,
  });
}



export async function getTransactionList(postData) {
  return await request({
    url: '/p2p-transaction/list',
    method: "post",
    data: postData,
  });
}

export async function getTransactionByRefId(refId) {
  return await request({
    url: `/p2p-transaction/${refId}`,
    method: "get",
  });
}

export async function createTransaction(postData) {
  return await request({
    url: '/p2p-transaction/create',
    method: "post",
    data: postData,
  });
}

export async function updateTransaction(postData) {
  return await request({
    url: '/p2p-transaction/update',
    method: "post",
    data: postData,
  });
}

export async function getEarliestTransactionTimestamp() {
  return await requestWithoutAxiosError({
    url: '/p2p-transaction/earliest',
    method: "get",
  });
} 