import request from "@/utils/request";

export async function getAllCategory(postData) {
  return await request({
    url: '/generic-record/list',
    method: "post",
    data: postData,
  });
}

export async function getCategoryById(id) {
  return await request({
    url: `/generic-record/${id}`,
    method: "get",
  });
}

export async function addCategory(postData) {
  return await request({
    url: "/generic-record/create-category",
    method: "post",
    data: postData,
  });
}

export async function updateCategory(postData) {
  return await request({
    url: "/generic-record/update-category",
    method: "post",
    data: postData,
  });
}

export async function getCategoryDropdownList() {
  return await request({
    url: '/generic-record/get-category-dropdown-list',
    method: "get",
  })
}