
<template>
  <div id="app">
    <component :is="layout">
      <transition appear name="slide-from-right">
        <router-view />
      </transition>
    </component>
    
    <transition name="back-to-top-sliding">
      <div 
        id="back-to-top" 
        v-if="showBackToTop"
        @click="backToTop"
      >
        <i class="custom-icon icon-scroll-top" />
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { MOBILE_MAX_WIDTH, LAYOUT_DEFAULT } from "@/constants"
import { EventBus } from "@/utils/event-bus.js"
import debounce from 'lodash/debounce'

export default {
  computed: {
    layout() {
      return (this.$route.meta.layout || LAYOUT_DEFAULT) + "-layout";
    },
    ...mapState('app', ['isMobile']),
    ...mapGetters(["isAuthenticated"])
  },
  data() {
    return {
      showBackToTop: false,
    }
  },
  mounted() {
    if (this.isAuthenticated) {
      // Get user preferred language
      this.$store.dispatch("auth/getCurrentUserProfile")
      .then(res => {
        if (res.language?.code != null && !this.$route.query.locale) {
          this.$store.dispatch('app/setLocale', { value: res.language?.code, updateBE: false })
        }
        EventBus.$emit("success-get-profile")
      })

      // Start loop (initial call)
      this.$store.dispatch("notification/loopCheckNotification")
      this.$store.dispatch("app/loopStayAlive")
      this.$store.dispatch("p2p-market/loopGetEarliestTransactionTimestamp")
    }

    // Mobile view checking
    this.checkIsMobile() // initial checking

    // check isMobile again when resized
    window.addEventListener('resize', 
      debounce(this.checkIsMobile, 100)
    , true);

    // Translations
    this.$store.commit("app/SET_TRANSLATION_FN", this.translate)
    this.$store.dispatch('app/getAllLanguage');

    // Others
    this.$store.dispatch('app/getAllCurrency');
    this.$store.dispatch('app/getAllChannelLink');
    this.$store.dispatch('app/getAllSiteSettings');

    // Whenever user leave/enter tabs
    EventBus.$on("toggleTabActive", isActive => {
      if (isActive && this.isAuthenticated) {
        this.$store.dispatch("notification/loopCheckNotification")
        this.$store.dispatch("app/loopStayAlive")
        this.$store.dispatch("p2p-market/loopGetEarliestTransactionTimestamp")
      } else {
        this.$store.dispatch("notification/cancelLoopCheckNotification")
        this.$store.dispatch("app/cancelLoopStayAlive")
        this.$store.dispatch("p2p-market/cancelLoopGetEarliestTransactionTimestamp", false)
      }
    });

    // Back to top
    window.addEventListener('scroll', debounce(this.onScroll, 100))
  },
  methods: {
    checkIsMobile() {
      const width = document.body.clientWidth
      const isMobile = width < MOBILE_MAX_WIDTH
      this.$store.dispatch("app/setIsMobile", isMobile)
      if (isMobile) {
        document.body.classList.add('mobile');
      } else {
        document.body.classList.remove('mobile');
      }
    },
    translate(code) {
      return this.$t(code)
    },
    backToTop() {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    },
    onScroll() {
      const tolerance = -400
      const idYPosition = document.getElementById("app").getBoundingClientRect().top;
      this.showBackToTop = idYPosition < tolerance
    },
  },
  watch: {
    isAuthenticated(yes) {
      if (yes) {
        this.$store.dispatch("notification/loopCheckNotification")
        this.$store.dispatch("app/loopStayAlive")
        this.$store.dispatch("p2p-market/loopGetEarliestTransactionTimestamp")
      }
    },
    "$route.query.locale"(val) {
      console.log(val)
      if(val){
        this.$store.dispatch('app/setPreviewLocale', val)
      } else {
        const lang = localStorage.getItem("language")
        this.$store.dispatch('app/setLocale', { value: lang, updateBE: false })
      }
    }
  }
};
</script>

<style lang="scss">
// import third party first
@import "https://unpkg.com/element-ui/lib/theme-chalk/index.css";
@import "@/assets/style/element-variables.scss";

// Then only import local, to allow overriding
@import "@/assets/style/main.scss";
@import "@/assets/style/popper.scss";
@import "@/assets/style/_variables.scss";

#back-to-top {
  position: fixed;
  bottom: 120px;
  right: 40px;
  cursor: pointer;
  height: 48px;
  width: 48px;
  border-radius: 50%;
  z-index: 102;
  >i {
    color: $white;
    font-size: 30px;
    line-height: 37px;
    text-align: center;
    height: 48px;
    width: 48px;
    background-size: 48px 48px;
  }
}

.mobile #back-to-top {
  bottom: 60px;
  right: 20px;
}

</style>