import Vue from "vue";
import Router from "vue-router";

import { LAYOUT_DEFAULT, LAYOUT_BO } from "@/constants";
import { ifAuthenticated, ifNotAuthenticated } from "@/utils/auth";
import PassThrough from "@/views/pass-through";
import { LazyLoadComponentWrapper } from "@/utils/lazy-load-component-wrapper";

/* Router Modules */
import publicRouter from "./modules/public"
import dashboardRouter from "./modules/bo/dashboard"
import articleRouter from "./modules/bo/article"
import advertisementRouter from "./modules/bo/advertisement"
import coinHistoryRouter from "./modules/bo/coin-history"
import p2pMarketRouter from "./modules/bo/p2p-market"
import userRouter from "./modules/bo/user"
import categoryRouter from "./modules/bo/category"
import channelRouter from "./modules/bo/channel"
import commentRouter from "./modules/bo/comment"
import complainRouter from "./modules/bo/complain"
import siteSettingsRouter from "./modules/bo/site-settings"
import settingsRouter from "./modules/bo/settings"
import notificationRouter from "./modules/bo/notification"
import profileRouter from "./modules/bo/profile"

Vue.use(Router);

export const allRoutes = [
  publicRouter,
  // all auth
  {
    path: "/auth",
    hideFromWebMenu: true,
    component: PassThrough,
    meta: {
      layout: LAYOUT_DEFAULT,
    },
    beforeEnter: ifNotAuthenticated,
    children: [
      {
        path: "sign-in",
        component: () => LazyLoadComponentWrapper(import("@/views/bo/auth/sign-in.vue")),
        meta: {
          layout: LAYOUT_DEFAULT,
          hideChannelLinkFooter: true
        },
      },
      {
        path: "sign-up",
        component: () => LazyLoadComponentWrapper(import("@/views/bo/user/detail.vue")),
        meta: {
          layout: LAYOUT_DEFAULT,
          hideChannelLinkFooter: true
        },
      },
    ]
  },
  // BO all routers
  {
    path: "/bo",
    redirect: "/bo/dashboard",
    component: PassThrough,
    meta: {
      layout: LAYOUT_BO,
    },
    beforeEnter: ifAuthenticated,
    children: [
      dashboardRouter,
      userRouter,
      categoryRouter,
      channelRouter,
      commentRouter,
      complainRouter,
      articleRouter,
      p2pMarketRouter,
      advertisementRouter,
      coinHistoryRouter,
      siteSettingsRouter,
      settingsRouter,
      profileRouter,
      notificationRouter,
      // add me more here
    ]

  },
  {
    path: "/404",
    component: () => LazyLoadComponentWrapper(import("@/views/error-page/404")),
    meta: {
      layout: LAYOUT_DEFAULT
    },
  },
  {
    path: "/testing",
    component: () => LazyLoadComponentWrapper(import("@/views/testing")),
    meta: {
      layout: LAYOUT_DEFAULT
    },
  },
];


const createRouter = () =>
  new Router({
    mode: "history",
    scrollBehavior: () => ({ y: 0, x: 0, top: 0, left: 0 }),
    routes: allRoutes
  });

const router = createRouter();

router.beforeEach((to, _, next) => {
  if (to.matched.length == 0) {
    next('/404')
  } else {
    next()
  }
})


export default router