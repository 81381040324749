<!-- This footer is for all pages -->
<template>
  <div id="footer">
    <div class="copyright">
      <p class="text-grey">
        {{ $t("COPYRIGHT") }} &copy; 2022 {{ RESERVED_KEYWORD }}
      </p>
    </div>
  </div>
</template>

<script>
import { generalMixin } from "@/utils/general-mixin.js"
import { RESERVED_KEYWORD } from "@/constants"

export default {
  name: "Footer",
  mixins: [ generalMixin ],
  computed: {},
  data() {
    return {
      RESERVED_KEYWORD // for html
    }
  }
};
</script>

<style lang="scss">
#footer {
  @import "@/assets/style/_variables.scss";

  width: 100vw;
  z-index: 101;
  background-color: #fff;
  position: fixed;
  bottom: 0;
  left: 0;
  transition: width 0.28s;
  box-shadow: 0px -1px 15px #0000001a;
  height: 2rem;
  line-height: 2rem;
  vertical-align: middle;
  height: $footerHeight;
  display: flex;
  
  .copyright {
    padding: 0;
    background: #fff;
    margin: auto;
    p {
      text-align: center;
      font-size: .75rem;
      margin: 0;
      color: #464646;
    }
    a {
      color: $primary;
    }
  }
}
</style>