import request from "@/utils/request";
import { requestWithoutAxiosError } from "@/utils/request"


export async function checkNotification() {
  return await requestWithoutAxiosError({
    url: '/notification/check',
    method: "get",
  });
}

export async function getNotificationList(postData) {
  return await request({
    url: '/notification/list',
    method: "post",
    data: postData,
  });
}

export async function readNotification(postData) {
  return await request({
    url: '/notification/read',
    method: "post",
    data: postData,
  });
}