import { LAYOUT_BO } from "@/constants";
import { LazyLoadComponentWrapper } from "@/utils/lazy-load-component-wrapper";
import { ifAuthenticated } from "@/utils/auth";
import PassThrough from "@/views/pass-through";

const articleRouter = {
  path: "article",
  component: PassThrough,
  beforeEnter: ifAuthenticated,
  meta: {
    layout: LAYOUT_BO,
    menuTitleTransCode: "NEWS_ARTICLES",
    menuIcon: "icon-route-article",
    allowedRoles: ["ADMIN", "MANAGER", "OFFICER", "MEMBER"]
  },
  children: [
    {
      path: "",
      component: () => LazyLoadComponentWrapper(import("@/views/bo/article/list.vue")),
      meta: {
        layout: LAYOUT_BO,
        breadcrumbTransCodeList: ["NEWS_ARTICLES"],
        allowedRoles: ["ADMIN", "MANAGER", "OFFICER", "MEMBER"]
      },
    },
    {
      path: "detail/:id(\\d+)?",
      component: () => LazyLoadComponentWrapper(import("@/views/bo/article/detail.vue")),
      meta: {
        layout: LAYOUT_BO,
        breadcrumbTransCodeList: ["NEWS_ARTICLES", "NEWS_ARTICLES_DETAIL"],
        allowedRoles: ["ADMIN", "MANAGER", "OFFICER", "MEMBER_LEVEL_THREE","MEMBER_LEVEL_FOUR"]
      },
    }
  ]
};

export default articleRouter;
