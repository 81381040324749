import { getAdsPositionList } from "@/api/advertisement.js"

const state = {
  adsPositionList: [],
  draftAds: localStorage.getItem("draft-ads") || {},
}

const actions = {
  getAllAdsPosition({ commit }) {
    return new Promise((resolve, reject) => {
      getAdsPositionList().then(res => {
          if (res.status == 'ok') {
            commit('SET_ADS_POSITION_LIST', res.result.list)
            resolve(res.status)
          }
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  previewAds({ commit },data) {
    commit('SET_DRAFT_ADS', data)
  },
}

const mutations = {
  SET_ADS_POSITION_LIST: (state, data) => {
    state.adsPositionList = data
  },
  SET_DRAFT_ADS: (state, data) => {
    localStorage.setItem("draft-ads", JSON.stringify(data))
    state.draftAds = data
  },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
  };
  