import { Notification } from 'element-ui'
import { NOTIFY_DURATION, NOTIFY_POSITION } from "@/constants"
import store from "../store";

export const LazyLoadComponentWrapper = (promise) => {
  return promise
  // in future, can add .then or .finally
  .catch(e => {
    // For now, lazy load component may fail when new deployment is up
    // but current webpage is using the old version (fail to load the lazy component)

    // IF hit this error during local development? Restart npm run serve !
    Notification({
      type: "error",
      message: store.getters.t("ERROR_NEW_WEB_PAGE"),
      duration: NOTIFY_DURATION,
      position: NOTIFY_POSITION
    })
    console.error(e)
  })
};
