// NPM: MAIN
import Vue from "vue";

// PROJECT: MAIN
import App from "./App.vue";
import router from "./router";
import store from "./store";

// PROJECT: OTHER
require("./utils/imports");
import i18n from "./locales"

/**************************************************************************
 * VUE INSTANCE
 **************************************************************************/
Vue.config.productionTip = false;

document.title = process.env.VUE_APP_TITLE

export default new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount("#app");
